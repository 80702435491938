import styled from "styled-components";
import { SearchCard } from "../../styled";

export const StylePersonCard = styled(SearchCard)`
.deathday {
    margin: 0;
    margin-top: 15px;
    font-weight: bold;
  }

`